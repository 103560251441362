@import "../../scss/colors";
.progressBarWrapper {
    width: 100%;
    h2 {
        margin: 1rem 0 3rem 0;
    }
    .progressBar {
        width: 100%;
        height: 20px;
        border: 0.5px solid $color-primary;
        padding: 2px;
        border-radius: 10px;
        &-inner {
            height: 100%;
            background-color: $color-primary-light;
            border-radius: 15px;
            border: 0.5px solid $color-primary-mid;
        }
    }
}