@import "./scss/colors";

.App {
  text-align: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.app-wrapper {
  background-color: $color-background;
  height: 100%;
  box-sizing: border-box;
  padding: 3rem;
}

button {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px;
  font-weight: 16px;
  background: $color-primary-mid;
  color: white;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family: Inter, Roboto, Arial, sans-serif;
  line-height: 1.75;
  padding: 8px 24px;
  border-radius: 8px;
  transition: background-color;

  &:not(:disabled):hover {
    background: $color-primary;
  }

  &:disabled {
    background: $color-primary-light;
  }
}

.container {
  width: 100%;

  &__asset {
    padding: 12px 16px;
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    width: 100%;
    background: rgb(255, 255, 255);
    border-radius: 8px;
    border: 1px solid transparent;
    transition: all 0.5s;
    position: relative;

    &--active {
      position: relative;
      border-color: $color-primary;

      &:hover {
        background: $color-primary-light;
      }

      &::after {
        content: "\2713";
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: $color-primary;
        background: $color-primary-mid;
        position: absolute;
        right: 1.5rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &:hover {
      cursor: pointer;
      border-color: $color-primary;
    }

    &__logo {
      width: 40px;
      height: 40px;
      margin-right: 1rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-weight: bold;

      &__short {
        font-size: 12px;
        font-weight: normal;
      }

      &__full {
        text-align: left;
      }
    }

    &__copy {
      display: flex;
      justify-content: space-between;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        padding-right: 16px;
        font-size: 12px;
        min-width: 90%;
        word-break: break-all;
      }

      .copy__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border-left: 1px solid $color-primary;
        width: 50px;

        &-icon {
          margin-left: 10px;

          &:hover {
            color: $color-primary;
          }
        }
      }
    }
  }

  &__buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  &--marginTop {
    margin-top: 2rem;
  }
}

.Toastify__toast-theme--light {
  background: $color-primary-mid;
  color: #000000;
}

.text {
  font-size: 12px;
  margin-bottom: 1rem;

  &__align--left {
    text-align: left;
  }

  &__tooltip {
    margin: 0;
  }
}

.tooltip {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;

  &__icon {
    margin-left: 4px;
    color: $color-primary;
    outline: none;
  }

  &__base {
    max-width: 500px;
    text-align: left;
  }
}
