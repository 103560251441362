.error-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.not-found {
    width: 100px;
    height: 100px;
}