.payment-wrapper {
  width: 100%;
}

.btn-link {
  width: 100%;
  margin-top: 0.5rem;
  button {
    width: 100%;
  }
}
