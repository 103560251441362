@import "./scss/colors";
@import "./App";
@import "./scss/media";

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
  &:link,
  &:visited {
    text-decoration: none;
  }
}
