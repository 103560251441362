@import "../../../../scss/colors";
.payment__status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        font-weight: normal;
        font-size: 20px;
        margin-top: 2rem;
    }
}

.status {
    width: 100px;
    height: 100px;
    &__success {
        color: $success;
    }
    &__failed {
        color: $danger;
    }
    &__expired {
        color: $info;
    }
    &__reload {
        font-weight: bold;
        &:hover {
            cursor: pointer;
            color: $color-primary;
        }
    }
}