@media screen and (max-width: 480px) {
  .app-wrapper {
    padding: 1rem;
  }

  .invoice {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 2rem;
  }

  .container__asset {
    &--active {
      &::after {
        display: none;
      }
    }
  }
}
