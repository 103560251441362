@import "../../scss/colors";
.invoice {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 5rem 4rem 5rem;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
    box-shadow: 0px -4px 30px rgb(0 0 0 / 5%);
    border-radius: 24px;
    position: relative;
    button {
        margin-top: 10px;
        width: max-content;
    }
    &__data {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 3rem;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid $color-primary-light;
    }
    &__title,
    &__amount,
    &__description {
        font-weight: bold;
        margin-bottom: 1rem;
        word-break: break-all;
    }
    &__title {
        font-size: 18px;
    }
    &__description {
        font-size: 14px;
    }
    &__amount {
        font-size: 14px;
    }
    &__logo {
        position: absolute;
        right: 15px;
        top: 15px;
    }
}